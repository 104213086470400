$(document).on "turbolinks:load", ->
  video = document.querySelector('#kater-matze-header-movie')
  if video != null
    playVideoAgain = (e) ->
      setTimeout (->
        video.play()
        return
      ), 1000
      return
    video.addEventListener 'ended', playVideoAgain, false
