# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: https://coffeescript.org/

$(document).on "turbolinks:load", ->
  window.filterPrograms = () ->
    selectedFilters = new Array
    if $("#selected-filters div[data-filter-id]").length == 0
      $.ajax
        url: "/filter-programs"
        data: []
        dataType: "script"
        cache: false
    else
      $("#selected-filters div[data-filter-id]").each ->
        filterId = undefined
        filterId = $(this).data("filter-id")
        selectedFilters.push filterId
      $.ajax
        url: "/filter-programs"
        data: selected_filters: selectedFilters
        dataType: "script"
        cache: false

  window.sortFilters = () ->
    $( "#selected-filters br" ).remove();
    rowWidth = $("#selected-filters").outerWidth(true)
    sumOuterWidth = 0
    $("#selected-filters div[data-filter-id]").each (index) ->
      sumOuterWidth = sumOuterWidth + $(this).outerWidth(true)
      if sumOuterWidth > rowWidth
        $("</br>").insertBefore($(this))
        sumOuterWidth = $(this).outerWidth(true)

    if $("#alle-loeschen").length > 0
      if sumOuterWidth + $("#alle-loeschen").outerWidth(true) > rowWidth
        $("</br>").insertBefore("#alle-loeschen")


$(document).on "turbolinks:load", ->

  $(".filter-dropdown-pane ul li").on "click", ->
    if $(this).hasClass("selected")
      $(this).removeClass "selected"
      $("#selected-filters div." + $(this).attr("class")).remove()
      filterPrograms.call()
    else
      $(this).addClass "selected"
      if $("#selected-filters div[data-filter-id]").length == 1 && $("#alle-loeschen").length == 0
        $("#selected-filters").append("<div id='alle-loeschen'>Alle löschen <i class=\"fa fa-times-circle\"></i></div>")
      if $("#alle-loeschen").length == 1
        $("<div class=" + $(this).attr("class") + " " + "data-filter-id=" + $(this).data("filter-id") + ">" + $(this).text() + " <i class=\"fa fa-times-circle\"></i></div>").insertBefore($("#alle-loeschen"))
      else
        $("#selected-filters").append("<div class=" + $(this).attr("class") + " " + "data-filter-id=" + $(this).data("filter-id") + ">" + $(this).text() + " <i class=\"fa fa-times-circle\"></i></div>")

      if $("#selected-filters div[data-filter-id]").length > 2
        sortFilters.call()
      filterPrograms.call()

    if $("#selected-filters div[data-filter-id]").length > 0
      $("#program-selected-filters").slideDown(400)
    else
      $("#program-selected-filters").slideUp(400)

  $("#selected-filters").on "click", "div i",  ->
    $("#program-select-filters .filter-dropdown-pane").each ->
      $(this).foundation("close")
    if $(this).parent().attr("id") == "alle-loeschen"
      $("#selected-filters").empty()
      $(".filter-dropdown-pane ul li[data-filter-id]").removeClass("selected")
    else
      $(".filter-dropdown-pane ul li."+$(this).parent().attr("class")).removeClass("selected")
      $(this).parent().remove()
      if $("#selected-filters div[data-filter-id]").length == 1
        $("#alle-loeschen").remove()

    if $("#selected-filters div[data-filter-id]").length == 0
      $("#program-selected-filters").slideUp(400)
    else
      sortFilters.call()
    filterPrograms.call()

  # Close all other drop down panes
  # -------------------------------
  $("[data-dropdown]").on "show.zf.dropdown", ->
    current = $(this).attr("id")
    $("#program-select-filters .filter-dropdown-pane").each ->
      if ($(this).hasClass("is-open") && current != $(this).attr("id"))
        $(this).foundation("close")

  return