/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// ------
// Images
// ------
const images = require.context('../images/static', true)
const imagePath = (name) => images(name, true)

// ----------
// Javascript
// ----------
import $ from 'jquery'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// ----------
// Fancybox 3
// ----------
import _ from '@fancyapps/fancybox'
$('[data-fancybox="gallery"]').fancybox()

// ---------------
// Zurb Foundation
// ---------------
import 'foundation-sites/js/foundation'
document.addEventListener('turbolinks:load', () => $(document).foundation())
let preparePath = function(filePath) {
  return filePath.substring(2);
};

// ------
// Custom
// ------
import '../src/events'
import '../src/programs'
import '../src/matze_movie'
import '../src/cookie_bar'


// const video = document.querySelector('#kater-matze-header-movie')

// function playVideoAgain(e) {
//     setTimeout(function(){
//       video.play();
//     }, 1000);
// }

// video.addEventListener('ended',playVideoAgain,false)

// video.onended = (event) => {
//   setTimeout(function(){
//     video.play()
//   }, 2000)
// }