# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: https://coffeescript.org/

$(document).on 'turbolinks:load', ->
  $('[data-tabs]').on 'change.zf.tabs', ->
    tabID = this.baseURI.split("#")[1]
    # headerImage = 'assets/header/'+tabID+'.jpg'
    # $('#events-menu').css 'background-image', 'url('+headerImage+')'
    $('#events-menu').removeClass();
    $('#events-menu').addClass tabID
    return